table{
    text-align: center;
  }
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
td, th {
    padding: 10px;
  }
.customBtn {
    padding: 3px 15px;
    background: #6b6bd7;
    border-color: #6b6bd7;
    color: #fff;
  }

  .selectmonthsection h2 {
    margin-bottom: 20px;
}
.monthsection {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}
.monthsection__item {
  background: #fff;
  padding: 75px 0;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.monthsection__item:hover {
    box-shadow: rgb(0 0 0 / 30%) 0px 5px 15px;
    cursor: pointer;
}
.monthsection__item span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

/*React Data Table*/
.contentSection-card .rdt_TableHead .rdt_TableHeadRow {
  background: #fff !important;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.04), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
}

.sc-eDWCr {
  white-space: unset !important;
  text-overflow: unset !important;
  text-align: center !important;
  min-width: 90px;
}

.sc-csuSiG, .sc-hLBbgP {
  justify-content: center !important;
}

.sc-dkrFOg {
  background: #e9e9e9 !important;
}
@media screen and (max-width:992px){
    .monthsection {
      grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width:500px){
.monthsection  {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.monthsection__item{
  padding: 65px 0;
}

}