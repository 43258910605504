@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(241, 242, 244, 1);
}

:root{
  --darkmodebg : #30304d;
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
 }
 .mt-0{
  margin-top: 0 !important;
 }
 .mt-10{
  margin-top: 10px !important;
 }
 .mt-20{
  margin-top: 20px !important;
 }
 .mb-5{
  margin-bottom: 5px !important;
 }
 .mb-10{
  margin-bottom: 10px !important;
 }
 .my-10{
  margin-block:10px;
 }
 .mb-20{
  margin-bottom: 20px !important;
 }
 .fs-20{
  font-size: 20px;
 }
 .fs-30{
  font-size: 30px;
 }
.max-w-400{
  max-width: 400px;
}
 .font-weight-bold{
  font-weight: 700;
 }
 .d-flex{
  display: flex !important;
 }
 .flex-direction-row-reverse{
  flex-direction: row-reverse;
 }
.align-items-start{
  align-items: flex-start !important;
}
 .justify-content-center{
  justify-content: center;
 }
 .gap-10{
  gap: 10px;
 }
 .d-inline-block{
  display: inline-block;
 }
 .urduFont,  .urduFont > *, .urduFont  div,  .urduFont  h3,   .urduFont  span,  .urduFont  strong, .duaeShabeQadr{
  font-family: "Lateef", serif !important;
 }
 .duaeShabeQadr{
  font-size: 25px;
 }
 .contentSection-card.urduLangActive,
 .contentSection-card.urduLangActive p, 
 .contentSection-card.urduLangActive h4 {
  text-align: right;
  font-family: "Lateef", serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px !important;
}
.contentSection-card.urduLangActive h4 {
  font-weight: 700;
  direction: rtl;
}
a {
  color: unset;
  text-decoration: none;
}
a[href="/"] {
  cursor: auto;
}
a[title="Home"] {
  cursor: pointer;
}
.container ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}
body.active {
  overflow: hidden;
}
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 6%) 0px 5px 15px;
  position: relative;
}
body:has(.container.nav-slided), body:has(.custom-modal){
overflow-y: hidden;
}
body:has(.custom-modal) .Sidebar-Navigation > ul{
  filter: blur(2px);
}
.container.nav-slided:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000009e;
  height: 100%;
  width: 100%;
  z-index: 99;
  backdrop-filter: blur(3px);
  pointer-events: all;
}
.m-auto{
  margin: auto;
}
.text-center{
  text-align: center;
}
.contain {
  margin-left: 240px;
  padding: 20px;
  border-left: 1px solid #c3c3c3;
  transition: 0.3s all;
  min-height: calc(100vh - 205px);
}
.contentSection-card + .contentSection-card {
  margin-top: 20px !important;
}
.mt-12{
  margin-top: 12px;
}
.fs-22{
  font-size: 22px;
}
.ml-20{
  margin-left: 20px;
}
.page-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
}
.backBtn {
  padding: 9px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 20px;
  border: 1px solid #abb1ba;
  display: inline-flex;
}
.backBtn:hover {
    background: #fff;
}
.page-header__content{
  margin-left: 20px;
}
.pagetitle {
  margin-bottom: 8px;
}

/*Button Style*/
.btn-btn-primary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 6px;
  font-family: inherit;
  background: inherit;
  cursor: pointer;
  transition: 0.3s all;
  border: 1px solid #FFF;
  color: #fff;
}

.btn-btn-primary:hover {
  background: #fff;
  color: #000;
}
.btn-btn-secondary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 6px;
  font-family: inherit;
  background: inherit;
  cursor: pointer;
  transition: 0.3s all;
  border: 1px solid #000;
  color: #000;
}

.btn-btn-secondary:hover {
  background: #000;
  color: #fff;
}

.referenceTag {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: #595959;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
.custom_scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #80808052;
  border-radius: 10px;
  background: #f1f1f1;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2d9;
}
.contentSection:last-child .dash-btn-container {
  border-bottom: unset;
}
  
/*Shabe Barat*/
.imgSection {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.imgSection  img {
  max-width: 390px;
}

@media screen and (max-width:992px){

.searchInput {
  display: none;
}
.contain, .Footer {
  margin-left: 0px !important;
}
.contain {
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-left: unset;
}
.container.nav-slided .header-section__item .logo {
  display: none;
}
.Footer {
  border-left:unset !important;
}
}




.h-38{
height: 38px;
}
.w-200{
  width: 200px;
  }
  .h-20{
    height: 20px;
    }
    .w-240{
      width: 240px;
      }
      .w-220{
        width: 220px;
        }
    .mb-8{
      margin-bottom: 8px;
    }

    .mt-60{
      margin-top: 60px;
    }
.bg-grey{
    background-color: #c6c6c6;
  }


  /* Dark Mode */
  .container.darkMode,  .container.darkMode .accordion-title {
    color: #fff;
}

.container.darkMode .header-section {
  background: var(--darkmodebg);
}
.container.darkMode .logo,
.container.darkMode .mob-logo,
.container.darkMode .duroodeibraheemi,
.container.darkMode .invert{
  filter: invert(1);
}

.container.darkMode .header-section {
  box-shadow: 0 0 3px #1a1a2f;
}

.container.darkMode .moonIcon,
.container.darkMode .hamburgerIcon,
.container.darkMode .crossIcon{
  box-shadow: 0 2px 3px #1a1a2f;
}

.container.darkMode .Dashboard,
.container.darkMode .contain,
.container.darkMode .changeLangSection
{
  background: var(--darkmodebg);
}

.container.darkMode .btn-btn-secondary {
  color: #fff;
  border-color: #fff;
}

.container.darkMode .Sidebar-Navigation {
  background: #2c2c41;
}


.container.darkMode .contain,
.container.darkMode .Footer {
  border-left: unset;
}

.container.darkMode .backBtn:hover {
  background: #363651;
}

.container.darkMode .contentSection-card,
 .container.darkMode .Footer {
  background: var(--darkmodebg);
  box-shadow: 0 2px 8px #1a1a2f;
  color: #fff !important;
}


.container.darkMode .sc-dmctIk{
  border-radius: unset;
}

.container.darkMode .rdt_Pagination {
  background: var(--darkmodebg);
  color: #fff !important;
}
.container.darkMode .sc-gikAfH  {
  color: #fff;
  fill: #fff;
}
/* .hIWEKa {
  top: -0px !important;
} */
/* data table */
.container.darkMode .sc-jrcTuL,
.container.darkMode .contentSection-card .rdt_TableHead .rdt_TableHeadRow {
  background: #282849  !important;
  box-shadow: 0 2px 3px #1a1a2f;
  color: #fff;
}




.container.darkMode .SliderSection .slick-prev, 
.container.darkMode .SliderSection .slick-next{
  color: #fff;
}

.container.darkMode .monthsection__item,
.container.darkMode .dailyTime__item,
.container.darkMode .btn-btn-secondary:hover,
.container.darkMode .SliderSection .slick-prev:hover, 
.container.darkMode .SliderSection .slick-next:hover{
  background: #282849;
}
.container.darkMode .dailyTime__item.active{
  color: #000;
}
.container.darkMode .referenceTag{
  color: #8383a6;
}


.card_container__Item{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:10px;
}

.card_container{
  display:grid;
  grid-template-columns:repeat(2, 1fr);
  gap:15px
  
}


/* react table header sticky */
.rdt_TableHeadRow div[data-column-id="1"] {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 3;
}

.darkMode .rdt_TableHeadRow div[data-column-id="1"],
.darkMode .jfdhAf,.darkMode  .rdt_TableBody > div div[data-column-id="1"]{
  background:#282849;
}
.rdt_TableHead{
  z-index: 3 !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;  /* Customize loader color */
}

.changeLangSection label {
  padding-right: 10px;
}
.changeLangSection select, .changeDist select{
  padding: 4px 4px 4px 0;
  border-radius: 6px;
  border-color: #a0a0a0;
  background: #fff;
  cursor: pointer !important;
}
.changeLangSection select:focus , .changeDist select:focus {
  border-color: #a0a0a0;
  outline: unset;
}
.changeLangSection {
  padding: 12px;
  position: sticky;
  top: 83px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  z-index: 99;
}

.custom-modal {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.custom-modal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  pointer-events: none;
  backdrop-filter: blur(3px);
}
span.icon-info {
  display: inline-flex;
  padding: 12px;
  background: #000;
  border: 1px solid #fff;
  border-radius: 50%;
}

span.icon-info svg{
fill: #fff;
}
.modal-content {
  max-width: 440px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 24px 20px;
  border-radius: 8px;
  z-index: 99;
  width: 100%;
  color: #000;
}
.modal-content .msg {
  margin-bottom: 22px;
  line-height: 24px;
  font-weight: 300;
}

.modal-content .btn-groups {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.modal-content .btn {
  padding: 12px 20px;
  border: 1px solid #eee;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s all;
}
.btn-confirm {
  background: #000 ;
  color: #fff;
}

.btn-confirm:hover {
  opacity: 0.7;
}
.btn-cancel {
  background: #fff ;
  color: #000;
}

.btn-cancel:hover {
  background: rgba(0, 0, 0, 0.1);
}





.wrapper {
  width: 100%;
  margin: 0 auto;
}

.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.8em 1.5em;
  border: solid 1px #ccc;
  border-radius: 8px;
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid currentColor;
  }

  &:hover,
  &.open {
    color: #000 !important;
    background: #eee;
    border-color: currentColor;
    position: sticky;
    top:134px;
    z-index: 9;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 6px solid;
    }
  }
}

.accordion-content {
  padding:1em 1PX;
}

.UrduNaflNamaz .accordion-title, .UrduNaflNamaz .nafl_namaze__item {
  flex-direction: row-reverse;
  direction: ltr;
  font-size: 22px;
  padding-block: 7px;
}

.UrduNaflNamaz .nafl_namaze__description > span {
  display: inline-block;
  text-align: right;
  direction: rtl;
  font-family: "Lateef", serif !important;
}

.UrduNaflNamaz h3,
.UrduNaflNamaz img{
  display: block;
  text-align: right;
  margin-left: auto;
  font-size: 36px;
}


.audioWrapper {
  background: #fff;
  padding: 20px 30px;
  border: 3px solid #fbc50b;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.audioplayer {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  margin-top: 15px;
}

.audioplayer-volume-button {
  background: #007d3a;
  color: #fff;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
  content: '';
  justify-content: center;
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 7px solid transparent;
  border-right: none;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #ffffff;
}

.audioplayer-time-current {
  margin-left: -45px;
}
.audioplayer-time {
  display: flex;
  width: 40px;
  justify-content: center;
  font-size: 12px;
  color: rgb(0 0 0);
  font-weight: bold;
}
.audioplayer-bar {
  position: relative;
  display: flex;
  margin: 0 12px;
  height: 20px;
  flex-basis: 0;
  flex-grow: 1;
  cursor: pointer;
}
.audioplayer-bar::before {
  content: '';
  position: absolute;
  top: 5px;
  width: 100%;
  height: 2px;
  background-color: #DDE2E6;
}
.audioplayer-bar > div {
  position: absolute;
  left: 0;
  top: 5px;
}
.audioplayer-time-duration {
  margin-right: -48px;
}

.audioplayer-volume-button {
  justify-content: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.audioplayer-volume-button > a{
  display: inline-flex;
  align-items: center;
}
.audioplayer-volume-button a:before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 9px solid #000;
  border-bottom: 8px solid transparent;
  border-left: none;
  top: -5px;
}
.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
  left: 10px;
  top: -2px;
  width: 6px;
  height: 6px;
  border: 6px double #000;
  border-width: 8px 8px 0 0;
  border-radius: 0 19px 0 0;
  transform: rotate(45deg);
}
.audioplayer-volume-adjust {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.audioplayer-volume-adjust > div {
  margin-left: -65px;
  position: relative;
  display: flex;
  width: 60px;
  height: 6px;
  cursor: pointer;
  background-color: #fbc50b;
}
.audioplayer-volume-adjust div div {
  position: absolute;
  top: 0;
  left: -8px;
  height: 6px;
  background-color: #feedb5;
}


@media screen and (max-width:600px){
  .modal-content {
    max-width: 320px;
    box-sizing: border-box;
  }
img{
  width: 100%;
}

/* Data Table CSS */

.sc-hLBbgP {
  min-width: 75px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.fs-30{
  font-size: 24px;
 }
 .pagetitle {
  margin-bottom: 4px;
  font-size: 20px;
}
.accordion-title {
  &:hover,
  &.open {
    top: 135px;
  }
}


.audioWrapper {
  padding: 15px;
}
.salatutTasbeeh{
  align-items: flex-start !important;
}
.UrduNaflNamaz .salatutTasbeeh{
  align-items: flex-end !important;
}
.salatutTasbeeh .rakatCounts{
  margin: auto;
}
}