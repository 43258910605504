.skip {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .skip:focus {
    position: static;
    width: auto;
    height: auto;
  }
.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99;
    padding: 4px 20px 0;
    background: #fff;
    box-shadow: 0 8px 24px rgb(229 228 230 / 40%);
    transition: 0.3s all;
}
.header-section__item {
    flex: 0 0 20%;
    cursor: pointer;
}
.header-section__item2 {
    flex: 0 0 84%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.logo {
    width: 100%;
    max-width: 100px;
}

.searchInput {
    width: 300px;
    padding: 10px 0;
    border-radius: 8px;
    border: 1px solid #918e8e;
    text-indent: 14px;
    display:none;
}

.date, .urdu-date {
    display: flex;
    align-items: center;
}
.moonIcon {
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    padding: 10px;
    border-radius: 50%;
    margin-left: 8px;
    transition: 0.5s all;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.header-section__timetable span {
    padding-left: 6px;
    display: inline-block;
    font-size: 18px;
}
.hamburgerIcon svg {
    font-size: 24px;
    cursor: pointer;
}

.hamburgerIcon {
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    padding: 10px;
    border-radius: 50%;
    margin-left: 8px;
    transition: 0.5s all;
    font-size: 20px;
    align-items: center;
    cursor: pointer;
    display: none;
}
.header-section__hadees{
    flex-basis: 60%;
}
.urduHadees {
    font-size: 30px;
    word-spacing: 14px;
    text-align: center;
}
@media screen and (max-width:992px){
.hamburgerIcon {
    display:inline-flex !important;
  }
  .header-section__hadees {
    display: none;
}
}
@media screen and (max-width:768px){
    .header-section__timetable {
        display: none;
    }
    /* .header-section__item2 {
        justify-content:flex-end;
    } */
    .header-section__hadees {
        flex-basis: 80%;
    }
}

@media screen and (max-width:600px){
.logo {
    width: 100%;
    max-width: 77px;
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 2;
}
.header-section {
    padding: 20px;
}
}

@media screen and (max-width:500px){
    .changeDist {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    .header-section__item2 {
        flex: 0 0 72%;
        justify-content: space-between;
    }
}

/* Modal Css */

/* Ensuring the modal is not shown initially */
.modal {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, .7);
    transition: all .4s;
  }
  
  /* When the modal is visible, adjust its styles */
  .modal.show {
    visibility: visible;
    opacity: 1;
  }
  
  .modal__content {
    border-radius: 4px;
    position: relative;
    width: 500px;
    max-width: 90%;
    background: #fff;
    padding: 1em 2em;
    text-align: center;
  }
  
  .modal__content button {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
  }
  
  .modal__content button:nth-child(2) {
    background-color: #f44336;
  }
  