
.timetableData__item {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    min-width: 430px;
}
.timetableData__item--first {
    display: inline-block;
    min-width: 280px;
}
/* .Dashboard{ 
    position: relative;
    background-image: url(../Images/quran.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} */

.Dashboard {
    position: relative;
    background: #fff;
    box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));
}
.timetableData {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 44px;
    width: 100%;
    max-width: 460px;
    background-color: #0c1529;
    box-shadow: rgb(100 100 111 / 70%) 0px 7px 29px 0px;
    border-radius: 10px;
    margin-top: 40px;
    color: #fff;
}
.timetableData__item:last-child {
    padding-bottom: unset;
}
/* data table css */
.sc-dmctIk  {
    min-height: 460px !important;
}

.dailyTime__item p span {
    font-size: 15px;
}
.dailyTime__item--namaz-time {
    font-size: 26px;
    font-weight: 600;
    z-index: 9;
}
.contentSection-card.dailyTime {
    /* box-shadow: unset; */
    padding: 16px;
}
/* .Dashboard.contain h1, .Dashboard.contain > p, .dashboard-header-section__tt {
    color: #000;
} */
.dashboard-header-section span {
    font-size: 18px;
    font-weight: 500;
    display: block;
}
.ani-move-down{
    animation: movedown 0.05s ease-out 0.5s;
    animation-fill-mode: backwards;
}
@keyframes movedown{
    0%{
        opacity: 0;
        transform: translateY(-40px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

.Dashboard.contain h1 {
    margin-bottom: 8px;
    font-size: 36px;
    /* animation: moveright 0.75s ease-out ;
    animation-fill-mode: backwards; */
}
@keyframes moveright{
    0%{
        opacity: 0;
        transform: translateX(-40px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.dashboard-header-section p {
    animation: moveleft 0.75s ease-out ;
    animation-fill-mode: backwards;

}
@keyframes moveleft{
    0%{
        opacity: 0;
        transform: translateX(40px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.dashboard-header {
    margin-bottom: 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
}   
.dash-btn-container {
    width: 100%;
    text-align: right;
    margin-top: 20px;
}

.timetableData + .dash-btn-container {
    padding-bottom: unset;
    border-bottom: unset;
    max-width: 540px;
}

/* slick slider */

.slidermain{
    max-width: 230px;
}
.contentSection:first-child {
    margin-top: 15px;
}

.contentSection-card {
    background: #fff;
    box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));
    border-radius: 8px;
    padding: 20px;
    border-top: 4px solid transparent;
    transition: 0.3s all;
    box-sizing: border-box;
}

.contentSection-card:hover {
    border-top: 4px solid;
}
.header-section__timetable {
    display: flex;
    justify-content: space-between;
}
.contentSection_title--content {
    line-height: 24px !important;
}
.contentSection_title--content:not(:first-child) {
    margin-top: 8px;
}
.contentSection_subheading {
    margin-top: 16px;
    line-height: 24px;
}
.contenetList {
    margin-top: 14px;
}
.contentSection_subtitle {
    margin-top: 24px;
}
.contenetList li {
    line-height: 30px;
}
.waqiaat {
    border-radius: 8px;
    background-color: #fff;
}
.subGrid-section {
    padding-bottom: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: calc(70% - 62px) 34%;
}

.waqiaat--section{
    margin-top: 20px;
    padding: 0 20px;
    margin-top: 20px;
    padding: 0 20px;
    max-height: 480px;
    overflow-y: scroll;
}
.waqiaat--section ul li {
    padding: 15px 0;
    line-height: 24px;
}
img.mob-logo {
    max-width: 120px;
}

/* Registration Form */
.registration-form {
    padding: 20px;
}   

.registration-form__item {
    padding: 0 20px;
    border-radius: 13px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.registration-form__item input {
    display: block;
    min-height: 40px;
    width: 100%;
    margin-top: 20px;
    border: unset;
    border-bottom: 1px solid #bfbfbf;
}
.registration-form__item input[type="button"] {
    background: transparent;
    border: 1px solid #847e7eee;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 10px;
    font-size: 16px;
    transition: 0.5s all;
    cursor: pointer;
}
.registration-form__item input[type="button"]:hover {
    background: #000;
    color: #fff;
}
.registration-form__item input:focus {
    outline: none;
}

.contentSection-card.dailyTime {
    display: grid;
    grid-template-columns: repeat(3, 1fr);;
    gap: 20px;
    border-top: unset;
    min-height: 330px;
}
.khatmeSaherAlert {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 6px 6px;
    padding: 10px 20px 10px 8px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #eee;
    max-width: 400px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}
.khatmeSaherAlert__heading {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
}

.khatmeSaherAlert__Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    position: relative;
}
.khatmeSaherAlert__Item p{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.khatmeSaherAlert__Item .timeLeft {
    position: relative;
    left: 0;
    transform: unset;
    top: unset;
    bottom: unset;
}

.dailyTime__item {
    padding: 45px 30px;
    border: 1px solid #eee;
    border-radius: 8px;
    /* box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 6px 6px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    row-gap: 20px;
    position: relative;
} 
.dailyTime__item p{
    z-index: 9;
}
.dailyTime__item.active, .khatmeSaherAlert.active {
    background: #ebffeb;
    padding-bottom: 95px;
    padding-top: 25px;
    position: relative;
    overflow: hidden;
    z-index: 9;
}
.khatmeSaherAlert.active {
    padding-block: 25px;
}
.dailyTime__item.active::after, .khatmeSaherAlert.active::after {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-conic-gradient(from var(--a), rgb(50, 202, 50), #fff, rgb(5 129 21), #fff, rgb(5 129 21));
    border-radius: 6px;
    animation: rotating 4s linear infinite;
    z-index: -1;
}
.dailyTime__item.active::before, .khatmeSaherAlert.active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background-image: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.6),hsla(0,0%,100%,0));
    animation: loaderAnimate 2.5s ease-in-out infinite;
    background-size: 200px 100%;
    z-index: 9;
  }
.dailyTime__item.active .emptyTag, .khatmeSaherAlert.active .emptyTag{
    position: absolute;
    inset: 2px;
    background: #dcffd3fa;
    border-radius: 8px;
    z-index: 0;
}
@property --a {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
}
@keyframes rotating {
    0% {
        --a: 0deg;
    }

    100% {
        --a: 360deg;
    }
}
.dailyTime__item.active[data-key='6']{
   padding-bottom: 25px;
} 
.dailyTime__item.active[data-key='2']::after {
    background: repeating-conic-gradient(from var(--a), #bdc316fa, #fff, #cbd116fa, #fff, #e9e939);
}
.dailyTime__item.active[data-key='2'] .emptyTag {
    background: #fdffbcfa;
}
.dailyTime__item.active[data-key='2']  .timeLeft,
.dailyTime__item.active[data-key='2']  .clockIcon::after  {
    background: #c1ab59;
}

@keyframes loaderAnimate {  
    0%{
      transform: translate3d(-200px,0,0);
    }
    100%{
      transform: translate3d(calc(200px + 100vw),0,0);
    }
  }
  .dailyTime__item.active[data-key='2'] .emptyTag::after {
    content: "";
    position: absolute;
    background-image: url('../Images/sunrise.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 86px;
    width: 86px;
    top: 49%;
    transform: translateY(-50%);
    right: 12px;
    opacity: 0.5;
}
.timeLeft {
    display: inline-flex;
    flex-direction: column;
    gap: 2px;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    background: #ef525a;
    border-radius: 4px;
    color: #fff;
    min-width: 135px;
    align-items: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
}


.clockIconContainer {
    padding: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -18px;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.clockIcon-subContainer {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid #EC5158;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}
.clockIcon::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 2px solid #EC5158;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.clockIcon::after {
    content: "";
    width: 80%;
    height: 3px;
    background-color: #EC5158;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotateHand 5s linear infinite;
    border-radius: 4px;
}
.dailyTime__item.active[data-key='2']  .clockIcon-subContainer , 
.dailyTime__item.active[data-key='2']  .clockIcon::before{
    border-color: #c1ab59 !important;
}
/* Animation for continuous rotation */
@keyframes rotateHand {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
/* .timeLeft::after {
    content: "";
    position: absolute;
    background-image: url('../Images/counter2.gif');
    background-repeat: no-repeat;
    background-size: cover;
    height: 30px;
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
    padding: 0;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
} */



.timeLeft .withTime{
    font-weight: 800;
}
/* SHABE BARAT CSS */
.nafl_namaze__section {
    margin-top: 20px;
}
.nafl_namaze__section {
    padding: 20px;
    border: 1px solid #EEE;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.nafl_namaze__section h3 {
    margin-top: 0;
}

.nafl_namaze__item {
    display: flex;
    gap: 20px;
    align-items: center;
    box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));
    padding: 20px;
    border-radius: 8px;
}
.nafl_namaze__description {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.nafl_namaze__item > span, .nafl_namaze__item .rakatCounts {
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 29px;
    text-align: center;
    min-width: 90px;
}

.container.darkMode .nafl_namaze__item > span{
    border: 1px solid #fff;
}

.nafl_namaze__section.urdu_section .nafl_namaze__item {
    justify-content: flex-end;
    text-align: right;
}


@media screen and (max-width:1200px){
    .dashboard-header{
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}   

@media screen and (min-width:993px){
    .waqiaat{
        position: sticky;
        top: 100px;
        width: 100%;
    }
    .header-section__timetable {
        min-width: 620px;
    }
}
@media screen and (max-width:992px){
    .subGrid-section {
        grid-template-columns: 100%;
    }
    .subGrid-section__first{
        padding-right: unset;
    }
    .subGrid-section__second{
        margin-bottom: 20px;
        order: -1;
    }
    .contentSection-card.dailyTime {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:767px){
    .contentSection-card{
        padding: 12px;  
    }
/* .subGrid-section__second {
    margin-top: 20px;
} */
}
@media screen and (max-width:600px){
    .registration-form {
        padding: 0 10px 10px;
        margin: 0 0 10px;
    }
    .su
    .waqiaat {
        height: auto;
    }
    .timetableData {
        max-width: 300px;
        margin-top: 40px;
        padding: 20px 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .timetableData__item {
        min-width: unset;
    }
    .urdu-date {
        padding-left: unset;
}
    .Dashboard.contain h1 {
        font-size: 30px;
    }
    .header-section__timetable span {
        font-size: 16px;
    }
    .timetableData {
        margin-top: 32px;
        font-size: 13px;
    }
    .timetableData__item--first {
        min-width: unset;
    }
    .Dashboard {
        background-size: cover;
    }
    img.mob-logo {
        max-width: 120px;
    }
 
.Sidebar-Navigation > ul {
    padding: 6px 20px 0;
}
.Dashboard.contain {
    min-height: unset;
}  
.contentSection-card.dailyTime{
    gap: 12px;
}

        /* shabe barat css  */

        .nafl_namaze__section {
            padding: 0;
            border: unset;
        }

        .nafl_namaze__item, .UrduNaflNamaz .nafl_namaze__item {
        flex-direction: column;
        }
        .nafl_namaze__description {
            gap: 10px;
        }
        .nafl_namaze__section.urdu_section .nafl_namaze__item {
            flex-direction: column-reverse;
        }
        .dailyTime__item p {
            padding-inline: 8px;
        }
}


@media screen and (max-width:500px){
    .dailyTime__item {
        padding: 25px 6px;
        font-size: 14px;
        row-gap: 10px;
    }
    .header-section__timetable {
        flex-direction: column;
        gap: 14px;
        min-height: 56px;
    }
    .dailyTime__item.active {
        padding-bottom: 80px;
        padding-top: 20px;
    }
    .dailyTime__item.active[data-key='6'] {
        padding-bottom: 20px;
    }
    .timeLeft {
        bottom: 20px;
        padding-left: 6px;
        min-width: 122px;
    }
    .timeLeft::after {
        width: 25px;
        height: 25px;
        border-width: 2px;
        right: -14px;
    }
    .contentSection-card.dailyTime {
        box-shadow: unset;
        padding: 0;
    }
    .UrduNaflNamaz .nafl_namaze__item > span, .UrduNaflNamaz .nafl_namaze__item .rakatCounts {
        padding-block: 5px;
    }
}
@media screen and (max-width:340px){
.timetableData {
    max-width: 262px;
}
}

/* footer css */
.Footer_Item--left, .Footer__social-icon {
    display: flex;
    align-items: center;
}
.Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c2c2c25e;
    padding: 14px 30px;
    margin-left: 240px;
    border-left: 1px solid #c3c3c3;
}
.Footer__social-icon a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    border: 1px solid transparent;
    padding: 9px;
    transition: 0.3s all;
}

.Footer__social-icon a:hover {
    transform: scale(1.1);
}